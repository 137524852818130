import React, { Component } from 'react';
import AOS from 'aos';

// Definieer de class component
class Header extends Component {
  componentDidMount() {
    AOS.init({
      offset: 200, // De animatie begint 200px voor het element in het zicht komt
      delay: 100, // De animatie start 100ms na het triggeren
      duration: 1000, // De animatie duurt 1000ms (1 seconde)
      easing: 'ease-in-out', // De animatie heeft een 'ease-in-out' easing
      once: true, // De animatie wordt slechts één keer afgespeeld
      mirror: false, // De animatie wordt niet opnieuw afgespeeld bij naar boven scrollen
      anchorPlacement: 'top-bottom', // De animatie begint wanneer de bovenkant van het element de onderkant van het scherm bereikt
    });
  }


  render() {

    return (

      <div id='homeContent' className="relative bg-cover bg-center background-image mt-12 md:mt-26"  >
        <div className="absolute inset-0 bg-black opacity-50"></div> {/* Overlay om tekst leesbaar te maken */}
        <div className="relative flex flex-col justify-center items-center h-full" data-aos="fade-up" data-aos-anchor-placement="top-center">
          <h1 class="pt-16 sm:pt-32 mb-4 text-xl font-extrabold leading-none tracking-tight text-white sm:text-3xl md:text-4xl lg:text-5xl xl:text-6xl dark:text-white">Beste schoonmaakservice in de<span class="underline underline-offset-3 decoration-8 decoration-green-400 dark:decoration-green-600"> Stad!</span></h1>
          <p className="text-white text-center mb-8 text-sm italic" >"Vertrouwd en Professioneel"</p>
          <button className="text-md md:text-2xl bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded">
            <a href="#contactContent" class="p-4">CONTACTEER ONS</a>
          </button>
          <svg xmlns="http://www.w3.org/2000/svg" className="md:h-12 md:w-12 h-8 w-8  text-white my-4 animate-bounceUp" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
            <path strokeLinecap="round" strokeLinejoin="round" d="M5 10l7-7m0 0l7 7m-7-7v18" />
          </svg>
          <div className="text-center text-md md:text-2xl font-bold leading-none text-white">
            {/* Voor kleinere schermen, opsplitsen in twee regels */}
            <span className="block lg:hidden">
              VRAAG GERUST VRIJBLIJVEND & GRATIS
            </span> 
            <span className="block lg:hidden">
              NAAR UW OFFERTE OP MAAT
            </span>

            {/* Voor grotere schermen, in één regel */}
            <span className="hidden lg:block">
              VRAAG GERUST VRIJBLIJVEND & GRATIS NAAR UW OFFERTE OP MAAT
            </span>
          </div>
        </div>
      </div>
    );
  }
}

export default Header;